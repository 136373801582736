



import { Component, Vue } from 'vue-property-decorator';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountCancelComplete extends Vue {
    private paramsName: any = '';
    private stepNavi: StepNaviData[] = [
      {
        title: '退会確認',
      },
      {
        title: '退会完了',
      },
    ];

    private mounted() {
        this.paramsName = this.$route.params.courceId;
    }
}
